<template>
  <section class="fullpage" id="expertise">
    <div class="waveUp">
      <div class="expertise">
        <div class="container">
          <div class="title mt-5">
            <h2 class="appear1">{{ title }}</h2>
          </div>
          <appExpertiseSlider class="appear2"></appExpertiseSlider>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import appExpertiseSlider from "./appExpertiseSlider.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "appExpertise",
  data() {
    return {
      title: "Expertise",
    };
  },
  components: {
    appExpertiseSlider,
  },
  mounted() {
    this.scrollExpertiseAnimations();
  },

  methods: {
    scrollExpertiseAnimations() {
      ScrollTrigger.defaults({
        // markers: {
        //   startColor: "black",
        //   endColor: "red",
        //   fontSize: "18px",
        //   fontWeight: "bold",
        //   indent: 20,
        // },
        toggleActions: "restart pause reverse pause",
        scrub: 1,
        start: "top center",
        end: "+=300px",
      });
      setTimeout(() => {
        let tlExpertise = gsap
          .timeline({ scrollTrigger: ".waveUp" })
          .to("#expertise .appear1", { opacity: 1, duration: 0.5, y: 0 })
          .to("#expertise .appear1", { rotate: "-5deg" })
          .to("#expertise .appear2", {
            opacity: 1,
            y: 0,
            duration: 1,
          })
          .to(".swiperNavigationContainer", { opacity: 1, y: 0, duration: 1 });
      }, 2000);
    },
  },
};
</script>

<style scoped>
h1,
h2 {
  color: white;
}

.expertise {
  /* background-color: #7737e6; */
  width: 100%;
  padding: 80px 0 80px 0;
}

.container {
  color: white;
  position: relative;
}

@media screen and (min-width: 576px) {
  /* Noch größer, noch mehr Regeln */

  .expertise {
    padding: 200px 0 220px 0;
  }
}
</style>
