import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";

import {
  faInstagram,
  faXing,
  faFontAwesome,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faMailBulk,
  faInstagram,
  faXing,
  faFontAwesome,
  faLinkedin,
  faFacebook
);

const mainApp = createApp(App);

mainApp.component("font-awesome-icon", FontAwesomeIcon);
mainApp.use(router);
mainApp.mount("#app");

import "./own";
