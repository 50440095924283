/* eslint-disable @typescript-eslint/no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import gsap from "gsap";
import "./assets/css/styles.css";

// Takes the viewport widths in pixels and the font sizes in rem
function clampBuilder(minWidthPx, maxWidthPx, minFontSize, maxFontSize) {
  const root = document.querySelector("html");
  const pixelsPerRem = 16;

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;
  console.log(
    `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
      slope * 100
    }vw, ${maxFontSize}rem )`
  );

  return `clamp( ${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem )`;
}

function is_touch_enabled() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

const mouseAnimation = () => {
  const mouse = document.querySelector(".mouse"),
    mousePointContainer = document.querySelector(".mousePointContainer"),
    mousePoint = document.querySelector(".mousePoint"),
    links = document.querySelectorAll("a, .link");

  mouse?.classList.add("visible");
  mousePointContainer?.classList.add("visible");
  mousePoint?.classList.add("visible");

  let posX = 0,
    posY = 0;

  let mouseX = 0,
    mouseY = 0;

  const tl = gsap.timeline({ repeat: -1 }),
    tlInner = gsap.timeline();

  tlInner.to(mousePoint, {
    duration: 1.5,
    repeat: -1,
    top: "15px",
    opacity: 0,
  });
  tl.to({}, 0.016, {
    repeat: -1,
    onRepeat: function () {
      posX += (mouseX - posX) / 9;
      posY += (mouseY - posY) / 9;

      tl.set(mouse, {
        css: {
          left: posX - 10,
          top: posY - 10,
        },
      });

      tl.set(mousePointContainer, {
        css: {
          left: mouseX,
          top: mouseY,
        },
      });
    },
  });
  let timeout;

  // tl.to(mousePointContainer, { duration: 1, repeat: -1, top: mouseY + 20 });

  document.addEventListener("mousemove", (e) => {
    tlInner.seek(0);
    tlInner.pause();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      tlInner.resume();
    }, 2000);
    mouseX = e.clientX;
    mouseY = e.clientY;
  });
  for (const link of links) {
    link.addEventListener("mouseover", (e) => {
      mouse?.classList.add("active");
      mousePoint?.classList.add("active");
    });
    link.addEventListener("mouseleave", (e) => {
      mouse?.classList.remove("active");
      mousePoint?.classList.remove("active");
    });
  }
};

// clampBuilder( 360, 840, 1, 3.5 ) -> "clamp( 1rem, -0.875rem + 8.333vw, 3.5rem )"
if (!is_touch_enabled()) {
  console.log("Kein Touch");
  setTimeout(() => {
    mouseAnimation();
  }, 2000);
}

clampBuilder(360, 840, 1.2, 2.2);
