<template>
  <section class="fullpage" id="intro">
    <div class="container">
      <div class="row">
        <div class="col-md-7 p-md-4">
          <h2 class="appear1 introHeadline">{{ headline }}</h2>
          <p class="appear2">{{ introText }}</p>
          <img class="introImg appear3" src="~@/assets/img/bspimg.jpg" alt="" />
        </div>
        <div class="col-md-5 p-md-4 appear4">
          <appIntroSkills></appIntroSkills>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import appIntroSkills from "../animations/appIntroSkills.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
  name: "appIntro",
  data() {
    return {
      headline: "Who am I",
      introText:
        "Ich bin freiberuflicher Webdesigner und -entwickler aus Düsseldorf. Ich erstelle Ihnen wunderschöne, professionelle Webseiten. Außerdem fotografiere und schule ich gerne. Ich liebe es, komplexe Anforderungen in anspruchsvolle Designs umzusetzen. Nur ein zufriedener Kunde ist ein guter Kunde.",
    };
  },
  components: {
    appIntroSkills,
  },
  methods: {
    scrollIntroAnimations() {
      let tlIntro = gsap
        .timeline({
          scrollTrigger: {
            trigger: "#intro",
            scrub: 1,
            start: "top top",
            end: "bottom 150px",
            pin: "#intro .container",
            id: "intro",
          },
        })
        .to("#intro .appear1", { opacity: 1, duration: 0.5, y: 0 })
        .to("#intro .appear2", { opacity: 1, duration: 0.5, y: 0 })
        .to("#intro .appear3", {
          opacity: 1,
          duration: 0.5,
          y: 0,
        })
        .to("#intro .appear4", { opacity: 1, duration: 0.5, y: 0 })
        .from(".progress-bar-line", { duration: 1, width: 0 })
        .to(".appear1", { rotate: "-5deg" })
        .add("introLabel")
        .to("#intro .appear1", { opacity: 0, duration: 0.5, y: -200 })
        .to("#intro .appear2", { opacity: 0, duration: 0.5, y: -200 })
        .to("#intro .appear3", {
          opacity: 0,
          duration: 0.5,
          y: -200,
        })
        .to("#intro .appear4", { opacity: 0, duration: 0.5, y: -200 })
        .from(".progress-bar-line", { duration: 1, width: 0 })
        .to(".appear1", { rotate: "-5deg" });

      let btn = document.querySelector(".intro_link");
      btn.addEventListener("click", () => {
        gsap.to(window, {
          scrollTo: tlIntro.scrollTrigger.labelToScroll("introLabel"),
        });
      });

      return tlIntro;
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 25px;
}

.introImg {
  max-width: 100%;
}

@media screen and (max-width: 450px) {
  .introImg {
    display: none;
  }
}
</style>
