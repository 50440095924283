/* eslint-disable vue/require-v-for-key */ /* eslint-disable */

<template>
  <section class="fullpage" id="kunden">
    <div class="container text-center">
      <div class="row appear">
        <div class="kundenwand">
          <div class="kundenwand--bg"></div>
          <div class="kundenwand--icons">
            <div class="row">
              <div
                class="col-6 col-md-3 kundenwand--icon mb-5"
                v-for="project in projects"
                :key="project.id"
              >
                <router-link :to="'/projekte/' + project.id">
                  <img
                    :src="require(`../../assets/img/kunden/${project.svg}`)"
                    :alt="[project.name]"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Projects from "@/projects.json";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  computed: {
    projects() {
      return Projects.projects;
    },
  },
  mounted() {
    // this.revealIcons();
  },
  methods: {
    revealIcons: () => {
      gsap.set(".kundenwand--icon", { y: 100 });

      ScrollTrigger.batch(".kundenwand--icon", {
        interval: 0.1, // time window (in seconds) for batching to occur.
        batchMax: 3, // maximum batch size (targets). Can be function-based for dynamic values
        onEnter: (batch) =>
          gsap.to(batch, {
            opacity: 1,
            y: 0,
            stagger: { each: 0.15, grid: [1, 3] },
            overwrite: true,
          }),
        onLeave: (batch) =>
          gsap.set(batch, { opacity: 0, y: -100, overwrite: true }),
        onEnterBack: (batch) =>
          gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
        onLeaveBack: (batch) =>
          gsap.set(batch, { opacity: 0, y: 100, overwrite: true }),
        // you can also define most normal ScrollTrigger values like start, end, etc.
        start: "20px bottom",
        end: "top top",
      });

      // when ScrollTrigger does a refresh(), it maps all the positioning data which
      // factors in transforms, but in this example we're initially setting all the ".kundenwand--icon"
      // elements to a "y" of 100 solely for the animation in which would throw off the normal
      // positioning, so we use a "refreshInit" listener to reset the y temporarily. When we
      // return a gsap.set() in the listener, it'll automatically revert it after the refresh()!
      ScrollTrigger.addEventListener("refreshInit", () =>
        gsap.set(".kundenwand--icon", { y: 0 })
      );
    },
  },
};
</script>

<style scoped>
.container {
  margin: 50px auto 100px auto;
}

.kundenwand {
  width: 100%;
  position: relative;
}

.kundenwand .row {
  padding-top: 150px;
  padding-bottom: 20px;
  align-items: center;
}

/* .kundenwand svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
} */

.kundenwand--bg {
  background: linear-gradient(270deg, #00fff6, #7737e6);
  background-size: 400% 400%;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
  transform: skewY(-5deg);

  -webkit-animation: fadingProjects 23s ease infinite;
  -moz-animation: fadingProjects 23s ease infinite;
  animation: fadingProjects 23s ease infinite;
}

.kundenwand--icons {
  z-index: 1;
  position: relative;
}

.kundenwand--icon {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.kundenwand--icon a {
  height: 100%;
}

.kundenwand--icon img {
  width: 100%;
  max-width: 120px;
  max-height: 80px;
  transition: transform 0.3s;
}

.kundenwand--icon img:hover {
  transform: scale(1.05);
}

@media screen and (min-width: 576px) {
  .kundenwand--icon img {
    max-width: 170px;
    max-height: 100%;
  }
}

@-webkit-keyframes fadingProjects {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes fadingProjects {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes fadingProjects {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
