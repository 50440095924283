<template>
  <transition name="lottie" appear>
    <div v-if="show" class="preloader">
      <div class="preloader--lottie">
        <lottieAnimation
          :autoPlay="true"
          path="animations/preloader_tastatur.json"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    hidePreloader() {
      setTimeout(() => {
        this.show = false;
      }, 2000);
      //   document.body.style.overflow = "hidden";
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted() {
    this.hidePreloader();
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
};
</script>

<style>
.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader--lottie {
  height: 100%;
  max-height: 100%;
  max-width: 1500px;
}

.lottie-enter-active,
.lottie-leave-active {
  transition: opacity 0.5s;
}
.lottie-enter, .lottie-leave-to /* .lottie-leave-active below version 2.1.8 */ {
  opacity: 0;
  transition: opacity 2.5s;
}
.lottie-leave-active {
  background-color: transparent;
}
</style>
