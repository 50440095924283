<template>
  <section class="fullpage-first" id="hero">
    <div
      class="
        container
        align-content-between
        flex-wrap
        justify-content-center
        hero
      "
    >
      <div class="hero__welcome">
        <div class="hero_welcome_text h-50 align-items-end d-flex">
          <div class="hero__welcome_text--container text-center flex-column">
            <div class="title">
              <h1 class="animate1">{{ message }}</h1>
            </div>
            <div class="subtitle mb-5">
              <span class="animate2">{{ submessage }}</span>
            </div>
            <!-- <div class="titleImgSVG">
              <img
                class="img-fluid w-100"
                src="@/assets/svgs/herotext.svg"
                alt=""
              />
            </div> -->
          </div>
        </div>
        <div class="hero_welcome_animation h-50 animate3">
          <LottieAnimation
            path="animations/hero/hero3.json"
            :autoPlay="true"
            :loop="true"
            @AnimControl="setAnimController"
            v-on:click="togglePause"
          ></LottieAnimation>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      message: "Hi, ich bin Christian",
      submessage: "Willkommen auf meiner Seite",
    };
  },
  components: {
    LottieAnimation,
  },
  methods: {
    STHero() {
      let tlHero = gsap.timeline();
      tlHero.add(this.STHeroBefore());
      tlHero.add(this.STHeroAfter());
      return tlHero;
    },

    STHeroBefore() {
      let tl = gsap
        .timeline()
        // .fromTo(
        //   "#topMenu, .header__button",
        //   { opacity: 0 },
        //   { duration: 0.5, opacity: 1 }
        // )
        .to("#topMenuInactive, .header__button, .social_icons", {
          duration: 0.5,
          opacity: 1,
        })
        .to(".animate1", { opacity: 1, duration: 0.5 })
        .to(".animate1", { rotate: "-5deg", duration: 0.8, ease: "back" })
        .to(".animate2", { opacity: 1, duration: 0.5 })
        .to(".animate2", { rotate: "355deg", duration: 0.8, ease: "back" })
        .to(".animate3", {
          opacity: 1,
          duration: 1,
          //   onComplete: this.playLottie,
        })
        .add("heroVisible"); // Label added
      return tl;
    },
    STHeroAfter() {
      let tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: "#hero",
            scrub: 1,
            start: "html",
            end: "bottom 50%",
            // markers: "true",
            pin: "#hero .container",
            id: "hero",
          },
        })
        .to(".animate1", { opacity: 0, duration: 0.5 })
        .to(".animate2", { opacity: 0, duration: 0.5 })
        .to(".animate3", {
          opacity: 0,
          //   onComplete: this.playLottie,
        });
      return tl;
    },

    playLottie() {
      this.anim.play();
    },

    setAnimController: function (anim) {
      this.anim = anim;
      anim.onComplete = () => console.log("anim 1 erstellt.");
    },
    togglePause: function () {
      this.anim.togglePause();
      console.log("Animation gestoppt");
      console.log(this);
    },
    continuePlay: function () {
      this.anim.play();
    },
  },
};
</script>

<style scoped>
.hero {
  position: relative;
}

.hero__welcome {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  height: 90vh;
  padding: 10vh 0;
  margin-left: auto;
  margin-right: auto;
}

.hero_welcome_text {
  margin: 0 auto;
  width: 100%;
}

.hero_welcome_text div {
  width: 100%;
}

.hwImg img {
  max-height: 100%;
}
</style>
