<template>
  <footer>
    <div class="waveUp">
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-3">
              <p>
                Christian Ottersbach<br />
                Base: Düsseldorf<br />
                <a href="tel:+4915678744195">+49 15678 744 195</a><br />
                <a
                  href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#109;&#097;&#105;&#108;&#064;&#099;&#045;&#111;&#116;&#116;&#101;&#114;&#115;&#098;&#097;&#099;&#104;&#046;&#100;&#101;"
                  >mail@c-ottersbach.de</a
                >
              </p>
              <p>Christian Ottersbach<br />&copy; 2022</p>
              <p>
                <a class="link" @click="revealOverlayer('impressum')"
                  >Impressum</a
                ><br />
                <a class="link" @click="revealOverlayer('datenschutz')"
                  >Datenschutz</a
                >
              </p>
            </div>
            <div class="col-6 col-sm-2">
              <p>
                <span> <a href="http://www.instagram.com">Instagram</a></span>
                <span
                  ><a
                    href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#109;&#097;&#105;&#108;&#064;&#099;&#045;&#111;&#116;&#116;&#101;&#114;&#115;&#098;&#097;&#099;&#104;&#046;&#100;&#101;"
                    >mail@c-ottersbach.de</a
                  ></span
                >
                <span
                  ><a
                    href="www.linkedin.com/in/christian-ottersbach
"
                    >LinkedIn</a
                  ></span
                >
                <span
                  ><a href="https://www.xing.com/profile/Christian_Ottersbach"
                    >Xing</a
                  ></span
                >
              </p>
            </div>
            <div class="col-6 col-sm-3">
              <p><b>Alle Projekte:</b></p>
              <ul>
                <li v-for="project in project" :key="project.id">
                  <router-link :to="`/projekte/` + project.id"
                    >{{ project.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-6 col-sm-4">
              <div class="designer">
                <span>designed with</span>
                <span class="love">
                  <LottieAnimation
                    path="animations/herzpfeil.json"
                    :loop="true"
                    :autoPlay="true"
                  ></LottieAnimation
                ></span>
                <span>by Virginie Mersch</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <app-impressum></app-impressum>
  <app-datenschutz></app-datenschutz>
</template>

<script>
import appImpressum from "@/components/overlayer/appImpressum.vue";
import appDatenschutz from "@/components/overlayer/appDatenschutz.vue";
import { gsap } from "gsap";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import Projects from "@/projects.json";
export default {
  name: "appFooter",
  computed: {
    project() {
      return Projects.projects;
    },
  },
  components: {
    LottieAnimation,
    appImpressum,
    appDatenschutz,
  },
  methods: {
    revealOverlayer(e) {
      let OLtl = gsap.timeline({
        ease: "power3.out",
      });

      if (e == "close") {
        OLtl.to("body", { duration: 0, overflow: "auto" }).to(
          ".overlayer--datenschutz, .overlayer--impressum",
          {
            duration: 1.5,
            top: "100%",
          }
        );
      } else {
        switch (e) {
          case "impressum":
            console.log("Case Impressum Junge");
            OLtl.to("body", { duration: 0, overflow: "hidden" }).to(
              ".overlayer--impressum",
              {
                duration: 1,
                top: "0%",
              }
            );
            break;
          case "datenschutz":
            console.log("Datenschutz Junge");
            OLtl.to("body", { duration: 0, overflow: "hidden" }).to(
              ".overlayer--datenschutz",
              {
                duration: 1,
                top: "0%",
              }
            );
            break;

          default:
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.waveUp {
  margin-top: -50px;
  position: relative;
  background-image: url("~@/assets/svgs/welle_footer.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.footer {
  padding: 100px 0 40px 0;
  position: relative;
}

span {
  display: block;
}
.designer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.designer span {
  display: inline-block;
  font-size: 0.8rem;
}
@media screen and (min-width: 450px) {
  .designer span:nth-of-type(1) {
    transform: translateX(23px);
    text-align: right;
  }
  .designer span:nth-of-type(3) {
    transform: translateX(-23px);
  }
}

.love {
  width: 100px;
}

@media screen and (min-width: 576px) {
  .footer {
    padding: 200px 0 40px 0;
  }
}
</style>
