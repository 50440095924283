<template>
  <div>
    <appNavi />
    <app-hero></app-hero>
    <app-intro></app-intro>
    <appExpertise></appExpertise>
    <appProjects></appProjects>
    <appKunden></appKunden>
    <appContact></appContact>
    <appFooter></appFooter>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import appNavi from "@/components/appNavi.vue";
import appHero from "@/components/hero/appHero.vue";
import appIntro from "@/components/intro/appIntro.vue";
import appExpertise from "@/components/expertise/appExpertise.vue";
import appProjects from "@/components/projects/appProjects.vue";
import appKunden from "@/components/kunden/appKunden.vue";
import appContact from "@/components/contact/appContact.vue";
import appFooter from "@/components/footer/appFooter.vue";


import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  name: "Home",
  components: {
    appNavi,
    appHero,
    appIntro,
    appExpertise,
    appProjects,
    appKunden,
    appContact,
    appFooter,

  },

  mounted() {
      this.$nextTick(function () {
          // Code that will run only after the
      // entire view has been rendered
      this.masterTimeline();
    this.scrollProjectsAnimations();
    });
    setTimeout(() => {
        ScrollTrigger.refresh();
}, 2000);
  },



  methods: {
    masterTimeline() {
      let master = gsap.timeline();
      master.add(appHero.methods.STHero(), "+=4")
      .add(appIntro.methods.scrollIntroAnimations());
      return master
    },

    scrollProjectsAnimations: () => {
        function appear() {
        gsap.defaults({ease: "power3"});
        gsap.set(".appear", {y: 100, opacity: 0.1});
        ScrollTrigger.batch(".appear", {
            start: "top bottom-=30%",
            onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, duration: 1, stagger: 0.15}),
            onLeaveBack: batch => gsap.to(batch, {opacity: 0, y: 100, stagger: 0.1})
        })
        }
        function rotateFont() {
            ScrollTrigger.batch(".rotateFont", {
                start: "top bottom-=30%",
                onEnter: batch => gsap.to(batch, {duration: 0.8, rotate: "-5deg", stagger: 0.2}),
                onLeaveBack: batch => gsap.to(batch, {rotate: "0deg"})
            })
        }
        appear();
        rotateFont()
    },


  },
});
</script>
