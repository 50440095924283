<template>
  <div class="overlayer overlayer--impressum">
    <div class="overlayer--inner">
      <div class="container">
        <div class="closeIcon" @click="closeOverlayer">
          <img src="@/assets/svgs/close.svg" />
        </div>
        <div class="row">
          <div class="col">
            <div class="text">
              <h2>Impressum</h2>
              <p>
                <strong>Christian Ottersbach</strong><br />
                Ackerstr. 99<br />
                40213 Düsseldorf<br />
                Telefon: +49 15678 744 195<br />
                E-Mail:
                <a
                  href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#109;&#097;&#105;&#108;&#064;&#099;&#045;&#111;&#116;&#116;&#101;&#114;&#115;&#098;&#097;&#099;&#104;&#046;&#100;&#101;"
                  >mail@c-ottersbach.de</a
                >
              </p>
              <!-- <p>
                <strong>Umsatzsteuer-ID:</strong><br />
                Umsatzsteuer-Identifikationsnummer nach §27a
                Umsatzsteuergesetz:<br />
                123456789
              </p>
              <h3>Disclaimer – rechtliche Hinweise</h3>-->
              <p>
                <strong>§ 1 Warnhinweis zu Inhalten</strong><br />
                Die kostenlosen und frei zugänglichen Inhalte dieser Webseite
                wurden mit größtmöglicher Sorgfalt erstellt.<br />
                Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für
                die Richtigkeit und Aktualität der<br />
                bereitgestellten kostenlosen und frei zugänglichen
                journalistischen Ratgeber und Nachrichten. Namentlich<br />
                gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors
                und nicht immer die Meinung des<br />
                Anbieters wieder. Allein durch den Aufruf der kostenlosen und
                frei zugänglichen Inhalte kommt keinerlei<br />
                Vertragsverhältnis zwischen dem Nutzer und dem Anbieter
                zustande, insoweit fehlt es am<br />
                Rechtsbindungswillen des Anbieters.
              </p>
              <p>
                <strong>§ 2 Externe Links</strong><br />
                Diese Website enthält Verknüpfungen zu Websites Dritter
                ("externe Links"). Diese Websites unterliegen der<br />
                Haftung der jeweiligen Betreiber. Der Anbieter hat bei der
                erstmaligen Verknüpfung der externen Links die<br />
                fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
                bestehen. Zu dem Zeitpunkt waren keine<br />
                Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss
                auf die aktuelle und zukünftige Gestaltung<br />
                und auf die Inhalte der verknüpften Seiten. Das Setzen von
                externen Links bedeutet nicht, dass sich der<br />
                Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu
                Eigen macht. Eine ständige Kontrolle der<br />
                externen Links ist für den Anbieter ohne konkrete Hinweise auf
                Rechtsverstöße nicht zumutbar. Bei<br />
                Kenntnis von Rechtsverstößen werden jedoch derartige externe
                Links unverzüglich gelöscht.
              </p>
              <p>
                <strong>§ 3 Urheber- und Leistungsschutzrechte</strong><br />
                Die auf dieser Website veröffentlichten Inhalte unterliegen dem
                deutschen Urheber- und<br />
                Leistungsschutzrecht. Jede vom deutschen Urheber- und
                Leistungsschutzrecht nicht zugelassene<br />
                Verwertung bedarf der vorherigen schriftlichen Zustimmung des
                Anbieters oder jeweiligen Rechteinhabers.<br />
                Dies gilt insbesondere für Vervielfältigung, Bearbeitung,
                Übersetzung, Einspeicherung, Verarbeitung bzw.<br />
                Wiedergabe von Inhalten in Datenbanken oder anderen
                elektronischen Medien und Systemen. Inhalte und<br />
                Rechte Dritter sind dabei als solche gekennzeichnet. Die
                unerlaubte Vervielfältigung oder Weitergabe<br />
                einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
                strafbar. Lediglich die Herstellung von<br />
                Kopien und Downloads für den persönlichen, privaten und nicht
                kommerziellen Gebrauch ist erlaubt.<br />
                Die Darstellung dieser Website in fremden Frames ist nur mit
                schriftlicher Erlaubnis zulässig.
              </p>
              <p>
                <strong>§ 4 Besondere Nutzungsbedingungen</strong><br />
                Soweit besondere Bedingungen für einzelne Nutzungen dieser
                Website von den vorgenannten<br />
                Paragraphen abweichen, wird an entsprechender Stelle
                ausdrücklich darauf hingewiesen. In diesem Falle<br />
                gelten im jeweiligen Einzelfall die besonderen
                Nutzungsbedingungen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import footer from "../footer/appFooter.vue";
export default {
  methods: {
    closeOverlayer() {
      console.log("hello from closeoverlayer");
      footer.methods.revealOverlayer("close");
    },
  },
};
</script>

<style></style>
