<template>
  <div
    v-for="skill in skills"
    :key="skill.id"
    id="pbl-1"
    class="progress-bar-linear"
  >
    <p class="progress-bar-text">{{ skill.name }}</p>
    <!-- <p class="progress-cont">
      <span class="main-color progress-bar-count">{{ skill.percent }}%</span>
    </p> -->
    <div class="progress-bar-skills">
      <div
        class="progress-bar-line main-color-bg"
        :style="'width:' + skill.percent + '%'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skills: [
        {
          name: "HTML5, CSS3, JS",
          percent: "55",
          id: 1,
        },
        {
          name: "node.js, vue.js, angular",
          percent: "44",
          id: 2,
        },
        {
          name: "Adobe Produkte",
          percent: "70",
          id: 3,
        },
        {
          name: "CMS: Wordpress, kirby, Typo3",
          percent: "70",
          id: 4,
        },
        {
          name: "Bootstrap 5, gsap",
          percent: "90",
          id: 5,
        },
      ],
    };
  },
};
</script>

<style>
.progress-bar-linear {
  margin: 20px 0;
}

.progress-bar-text {
}

.progress-bar-text,
.progress-cont {
  margin-left: 8px;
  margin-bottom: 6px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
}

.progress-cont {
  float: right;
  margin-right: 10px;
}

.progress-bar-linear .progress-bar-skills {
  position: relative;
  width: 100%;
  background-color: #dfdfdf;
  border-radius: 12px;
}

.progress-bar-skills .progress-bar-line {
  position: relative;
  width: 5%;
  height: 6px;
  background-color: #00fff6;
  -webkit-transition: all 1.6s ease-out;
  transition: all 1.6s ease-out;
  border-radius: 12px;
}
</style>
