<template>
  <appPreloader />
  <appSocial></appSocial>
  <div class="mouse"></div>
  <div class="mousePointContainer"><div class="mousePoint"></div></div>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" :key="$route.path" />
    </transition>
  </router-view>
  <!-- <appContact></appContact>
  <appFooter></appFooter> -->
</template>

<script>
import appPreloader from "@/components/appPreloader.vue";
import appSocial from "@/components/social.vue";
// import appFooter from "@/components/footer/appFooter.vue";

export default {
  components: {
    appPreloader,
    appSocial,
    // appContact,
    // appFooter,
  },
};
</script>

<style lang="scss">
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 1s;
// }
// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }

.fade-leave-to {
  opacity: 0;
  //   transform: translateX(-50vw);
}
.fade-leave-active {
  // während der transition
  transition: all 0.5s ease-in-out;
}

.fade-enter-from {
  opacity: 0;
  //   transform: translateX(50vw);
}

.fade-enter-active {
  transition: all 0.5s ease-in-out;
}

// .panel {
//   position: fixed;
//   width: 100%;
//   height: auto;
//   top: 0;
//   left: 0;
// }

// section:not(.first) {
//   opacity: 0;
//   visibility: hidden;
//   transform: scale(0.8);
// }
</style>
