<template>
  <section class="fullpage" id="kontakt">
    <div class="container mb-5">
      <div class="row appear">
        <div class="col text-center mb-5">
          <h2 class="rotateFont">Get in touch</h2>
        </div>
      </div>
      <!-- <div class="row appear">
        <div class="col">
          <p>
            Das Formular ist aktuell deaktiviert. Kontaktieren Sie mich direkt
            über Email
            <a
              href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#109;&#097;&#105;&#108;&#064;&#099;&#045;&#111;&#116;&#116;&#101;&#114;&#115;&#098;&#097;&#099;&#104;&#046;&#100;&#101;"
              >hier</a
            >.
          </p>
        </div>
      </div> -->
      <div class="row mt-5 appear">
        <div
          class="
            col-sm-8
            order-sm-last
            text-center
            d-flex
            justify-content-center
            align-items-start
          "
        >
          <img
            class="contactImg mb-5"
            id="contactImg"
            src="~@/assets/svgs/hello_sprechblase.svg"
            alt=""
            @click="formSuccessAction(true)"
          />
        </div>
        <div class="col-sm-4">
          <form
            id="contact-form"
            class="contact__form"
            ref="form"
            @submit.prevent="sendEmail"
          >
            <div class="Name wrap-input validate-input">
              <input
                type="text"
                name="from_name"
                id="from_name"
                class="input"
                value=""
                required=""
              />
              <!-- <label for="name">Name</label> -->
              <span class="focus-input" data-placeholder="Name"></span>
            </div>
            <div class="Email wrap-input validate-input">
              <input
                type="email"
                name="from_email"
                id="from_email"
                class="input"
                value=""
                required=""
              />
              <!-- <label for="email">Email</label> -->
              <span class="focus-input" data-placeholder="Email"></span>
            </div>
            <div class="Phone wrap-input validate-input">
              <input
                type="tel"
                name="from_phone"
                id="phone"
                class="input"
                value=""
              />
              <!-- <label for="phone">Telefon</label> -->
              <span class="focus-input" data-placeholder="Telefon"></span>
            </div>
            <div class="Message wrap-input">
              <textarea
                name="message"
                id="message"
                class="input"
                rows="5"
                required=""
              ></textarea>
              <span
                class="focus-input"
                data-placeholder="Ihre Nachricht"
              ></span>
            </div>

            <div class="container-contact2-form-btn">
              <div class="wrap-contact2-form-btn">
                <div class="contact2-form-bgbtn"></div>

                <button class="contact2-form-btn" type="submit" name="submit">
                  Senden
                </button>
              </div>
            </div>
            <div class="successMessage">
              <p>Nachricht erfolgreich verschickt.</p>
            </div>
            <div class="errorMessage">
              <p>Fehler beim übermitteln der Nachricht.</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import emailjs from "@emailjs/browser";
import "@/assets/css/contact.css";
export default {
  name: "appContact",
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_2mno1le",
          "template_qqvwi1c",
          this.$refs.form,
          "ZF49mQ6sWVa7VC_zX"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.resetInputs();
            this.formSuccessAction(true);
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.formSuccessAction(false);
          }
        );
    },
    formSuccessAction: function (e) {
      let bubble = document.querySelector("#contactImg");
      let successMessage = document.querySelector(".successMessage");
      let errorMessage = document.querySelector(".errorMessage");
      if (e === true) {
        console.log("e war true");
        gsap.to(successMessage, {
          opacity: 1,
          duration: 0.8,
        });
        gsap.to(bubble, { rotation: 360 * 2, duration: 1, ease: "power2.out" });
        gsap.to(successMessage, {
          opacity: 0,
          duration: 0.8,
          height: "0%",
          delay: 4,
        });
      }
      if (e === false) {
        console.log("war false");
        gsap.to(errorMessage, { opacity: 1, duration: 0.8 });
        gsap.to(bubble, {
          keyframes: {
            x: [0, 50, -50, 50, -50, 0],
            ease: "power3", // ease the entire keyframe block
          },
          duration: 1,
        });
        gsap.to(errorMessage, {
          opacity: 0,
          duration: 0.8,
          height: "0%",
          delay: 4,
        });
      }
    },
    resetInputs() {
      let inputs = document.querySelectorAll("#contact-form .input");
      for (const input of inputs) {
        input.value = "";
      }
    },
    applyFormClass() {
      let inputs = document.querySelectorAll("#contact-form .input");
      for (const input of inputs) {
        input.addEventListener("blur", () => {
          if (input.value != "") {
            input.classList.add("has-val");
          } else {
            input.classList.remove("has-val");
          }
        });
      }
    },
  },
  mounted() {
    this.applyFormClass();
  },
};
</script>

<style scoped>
.contactImg {
  max-width: 170px;
  margin-top: 10%;
}

@media screen and (min-width: 576px) {
  .contactImg {
    max-width: 250px;
  }
}

.successMessage,
.errorMessage {
  font-size: 0.8rem;
  opacity: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.successMessage {
  color: green;
}
.errorMessage {
  color: red;
}
</style>
