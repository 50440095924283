/* eslint-disable vue/require-v-for-key */ /* eslint-disable */
<template>
  <section id="projectSingle">
    <div>
      <appNaviProjects />
      <div class="container mt-5">
        <div class="row">
          <div class="col mb-100 mt-5">
            <h4 class="appear rotateFont">
              Projekt:<br />
              {{ project.name }}
            </h4>
          </div>
        </div>
        <div v-if="project.mockup1" class="row">
          <div class="col-12">
            <figure>
              <img
                :src="
                  require('@/assets/img/projekte/' +
                    this.project.id +
                    '/' +
                    this.project.mockup1)
                "
              />
            </figure>
          </div>
        </div>
        <div class="row">
          <div class="col-6"></div>
          <div class="col-6 appear mediacontainer" style="padding: 0">
            <div v-if="project.lottie">
              <LottieAnimation
                :path="project.lottie"
                :loop="true"
                @AnimControl="setAnimController"
                v-on:click="togglePause"
              ></LottieAnimation>
            </div>
            <video
              v-else-if="project.video1"
              class="videoclass"
              autoplay
              muted
              loop
            >
              <source
                :src="
                  require('@/assets/img/projekte/' +
                    this.project.id +
                    '/' +
                    this.project.video1)
                "
                type="video/mp4"
              />
              <p>Dieser Browser unterstützt html5 nicht.</p>
            </video>
            <figure v-else-if="project.bild1">
              <img
                :src="
                  require('@/assets/img/projekte/' +
                    this.project.id +
                    '/' +
                    this.project.bild1)
                "
              />
            </figure>
          </div>

          <div v-if="project.introtext" class="col-12 col-sm-12 smaller appear">
            <p>
              {{ project.introtext }}
            </p>
          </div>
          <div v-else class="appear">
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
              amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              dolor sit amet.
            </p>
          </div>
        </div>

        <div class="row appear mb-100">
          <div v-if="project.bild3" class="col-12 col-md-6 mt-5">
            <figure>
              <img
                :src="
                  require('@/assets/img/projekte/' +
                    this.project.id +
                    '/' +
                    this.project.bild3)
                "
              />
            </figure>
          </div>

          <div v-if="project.bild4" class="col-12 col-md-6">
            <figure>
              <img
                :src="
                  require('@/assets/img/projekte/' +
                    this.project.id +
                    '/' +
                    this.project.bild4)
                "
              />
            </figure>
          </div>
        </div>

        <div v-if="project.iframe" class="row appear mb-100">
          <div class="col">
            <div class="projectIframe">
              <iframe :src="project.url" frameborder="1"></iframe>
            </div>
          </div>

          <!-- Ende der row -->
        </div>
        <div v-if="project.story" class="row appear mb-100">
          <div class="col">
            <h5 class="rotateFont">Die Story hinter<br />diesem Projekt</h5>
            <p><b>Die Story: </b>{{ project.story }}</p>
          </div>
        </div>
      </div>
      <div class="container-fluid mb-100">
        <figure class="introImg">
          <img
            v-if="project.wavebg"
            :src="
              require('@/assets/img/projekte/' +
                this.project.id +
                '/' +
                this.project.wavebg)
            "
            alt="wavebg"
          />
          <img v-else src="~@/assets/img/working.jpg" alt="wavebg" />
        </figure>
      </div>
      <div class="container appear">
        <div v-if="project.techstack && project.url" class="row mt-5 mb-100">
          <div class="col-12">
            <div>
              <h5 class="rotateFont">Nerd-Buzzwordz:</h5>
              <ul class="text-center">
                <li v-for="techstack in project.techstack" :key="techstack">
                  - {{ techstack }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div v-if="project.url" class="col-12">
          <div class="text-center">
            <h5>Webseite:</h5>
            <a :href="project.url" target="_blank">{{ project.url }}</a>
          </div>
        </div> -->
        </div>
        <div v-else>
          <p>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </p>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <h5 class="rotateFont">Nächstes Projekt:</h5>
            <router-link :to="`/projekte/${nextProject.id}`">
              <img
                :src="require(`../assets/img/kunden/black/${nextProject.svg}`)"
                :alt="[nextProject.name]"
                class="projectPageNextImg"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <appContact></appContact>
    <appFooter></appFooter>
  </section>
</template>

<script lang="js">
import { defineComponent } from "vue";
import appNaviProjects from "@/components/appNaviProjects.vue";

import appContact from "@/components/contact/appContact.vue";
import appFooter from "@/components/footer/appFooter.vue"

import Projects from "@/projects.json";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default defineComponent ({
  components: {
    appContact,
    appFooter,
    appNaviProjects,
    LottieAnimation
  },
  computed: {
    projectsId() {
      return this.$route.params.id;
    },
    project() {
      return Projects.projects.find(
        (project) => project.id === this.projectsId
      )
    },
    nextProject() {
          let totalProjects = Object.keys(Projects.projects).length;
        //   let actualProjectIndex = Projects.projects.indexOf(this.project);
            let actualProjectIndex = this.project.index;
          let nextProject = actualProjectIndex + 1;
        //   console.log("Gesamtzahl Projekte:", totalProjects);
        //   console.log("Projektindex:", actualProjectIndex);
        //   console.log("Nächstes Projekt:", nextProject);
        //   console.log("Projekt:", this.project);
          let firstProject =  Projects.projects[0];
          console.log("Erstes:", firstProject);
            if (actualProjectIndex >= totalProjects ) {
                // console.log("this.project.index) > totalProjects:", firstProject);
                return firstProject
        } else {
            let nextObject = Projects.projects.find((project) => project.index === (this.project.index + 1) )
            // console.log("Nicht größer:", nextProject);
            return nextObject
        }
      }
  },
  mounted() {
    this.scrollProjectsAnimations();
    this.nextProject2()
  },
  methods: {
      nextProject2: function () {
          let totalProjects = Object.keys(Projects.projects).length;
        //   let actualProjectIndex = Projects.projects.indexOf(this.project);
        let actualProjectIndex = this.project.index;
          let nextProject = actualProjectIndex + 1;
        //   console.log("Gesamtzahl Projekte:", totalProjects);
        //   console.log("Projektindex:", actualProjectIndex);
        //   console.log("Nächstes Projekt:", nextProject);
        //   console.log("Projekt:", this.project);
          let firstProject =  Projects.projects[0];
        //   console.log("Erstes:", firstProject);
        //   return Projects.projects.find((project) => project.index === (this.project.index + 1) )
            if (actualProjectIndex >= totalProjects ) {
                // console.log("this.project.index) > totalProjects:", firstProject);
                return firstProject
        } else {
            let nextObject = Projects.projects.find((project) => project.index === (this.project.index + 1) )
            // console.log("Nicht größer:", nextProject);
            return nextObject
        }
        //   console.log("Länge:", Projects.projects.length());
      },
    setAnimController: function (anim) {
      this.anim = anim;
      anim.onComplete = () => console.log("anim 1 erstellt.");
    },
    togglePause: function () {
      this.anim.togglePause();
      console.log("Animation gestoppt");
      console.log(this);
    },
    continuePlay: function () {
      this.anim.play();
    },
    scrollProjectsAnimations: () => {
        function showMenu() {
        gsap.to("#topMenuInactive, .header__button, .social_icons", {
          duration: 0.5,
          opacity: 1,
        })
        }
        function appear() {
        gsap.defaults({ease: "power3"});
        gsap.set(".appear", {y: 100, opacity: 0.1});
        ScrollTrigger.batch(".appear", {
            start: "top bottom-=30%",
            onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, duration: 1, stagger: 0.15}),
            onLeaveBack: batch => gsap.to(batch, {opacity: 0, y: 100, stagger: 0.1})
        })
        }
        function rotateFont() {
            ScrollTrigger.batch(".rotateFont", {
                start: "top bottom-=30%",
                onEnter: batch => gsap.to(batch, {duration: 0.8, rotate: "-5deg", stagger: 0.2}),
                onLeaveBack: batch => gsap.to(batch, {rotate: "0deg"})
            })
        }
        showMenu();
        appear();
        rotateFont()
    },
  },
});
</script>

<style scoped>
img {
  width: 100%;
}
.smaller {
  padding: 50px 40px;
}

ul li {
  display: inline-block;
  margin-right: 5px;
  font-style: italic;
}

.projectIframe {
  width: 100%;
}

iframe {
  width: 100%;
  height: 50vh;
}

.introImg {
  position: relative;
}

.introImg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  background-image: url("~@/assets/svgs/wave1_trans2.svg");
  background-size: 100% 100%;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

.mediacontainer {
  overflow: hidden;
}

.videoclass {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  right: -1px;
  top: -1px;
}

.projectPageNextImg {
  width: 100%;
  max-width: 400px;
  max-height: 100px;
  margin: 3rem 0;
}
</style>
