<template>
  <div class="container mt-5 mb-4">
    <div class="row">
      <div class="col">
        <swiper
          :loop="true"
          :effect="'coverflow'"
          :grabCursor="true"
          :centeredSlides="true"
          :slidesPerView="'auto'"
          :coverflowEffect="{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }"
          :space-between="15"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :pagination="{
            dynamicBullets: true,
            clickable: true,
            el: '.swiper-pagination',
          }"
          :keyboard="{
            enabled: true,
          }"
          :autoplay="{
            delay: 40500,
            disableOnInteraction: true,
          }"
          :navigation="{
            nextEl: '.swiper-own-next',
            prevEl: '.swiper-own-prev',
          }"
          :breakpoints="{
            480: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }"
        >
          <swiper-slide
            ><div class="swiper_inner link">
              <div class="swiper_inner--anim">
                <div class="swiper_inner--anim--bg"></div>
                <lottieAnimation
                  @AnimControl="setAnimController"
                  :autoPlay="true"
                  v-on:mouseover="pause"
                  v-on:mouseout="continuePlay"
                  path="animations/expertise/webdev/webdev.json"
                />
              </div>
              <div class="swiper_inner--hl"><h3>Web Development</h3></div>
              <div class="swiper_inner--sl">
                <p>
                  Node.js oder php im Backend, vue.js oder Angular im Frontend.
                  Mit welcher Technologie auch immer, zusammen finden wir die
                  beste Lösung.
                </p>
              </div>
            </div></swiper-slide
          >
          <swiper-slide
            ><div class="swiper_inner link">
              <div class="swiper_inner--anim">
                <div class="swiper_inner--anim--bg"></div>
                <lottieAnimation
                  path="animations/expertise/webdesign/webdesign.json"
                />
              </div>
              <div class="swiper_inner--hl"><h3>Web Design</h3></div>
              <div class="swiper_inner--sl">
                <p>
                  Meine Apps sind komplett responsive und sind auf allen mobilen
                  Endgeräten nutzbar. Ein professionelles Design ist
                  grundsätzlich die Basis.
                </p>
              </div>
            </div></swiper-slide
          >
          <swiper-slide
            ><div class="swiper_inner link">
              <div class="swiper_inner--anim">
                <div class="swiper_inner--anim--bg"></div>
                <lottieAnimation
                  @AnimControl="setAnimController"
                  :autoPlay="true"
                  v-on:mouseover="pause"
                  v-on:mouseout="continuePlay"
                  path="animations/expertise/training/training.json"
                />
              </div>
              <div class="swiper_inner--hl"><h3>Training</h3></div>
              <div class="swiper_inner--sl">
                <p>
                  MS Office, Web-Themen, Bildbearbeitung: Ich biete ein
                  umfangreiches Portfolio an Schulungsangeboten. Das konnte ich
                  auch bereits im Schulungszentrum des Deutschen Bundestages
                  unter Beweis stellen.
                </p>
              </div>
            </div></swiper-slide
          >
          <swiper-slide
            ><div class="swiper_inner link">
              <div class="swiper_inner--anim">
                <div class="swiper_inner--anim--bg"></div>
                <lottieAnimation
                  @AnimControl="setAnimController"
                  v-on:mouseover="pause"
                  v-on:mouseout="continuePlay"
                  path="animations/expertise/fotografie/foto.json"
                />
              </div>
              <div class="swiper_inner--hl"><h3>Fotografie</h3></div>
              <div class="swiper_inner--sl">
                <p>
                  Im Zuge der Webprojekte entsteht immer wieder der Bedarf nach
                  passenden Bildern. Auch hier kann ich unterstützen.
                </p>
              </div>
            </div></swiper-slide
          >

          <swiper-slide
            ><div class="swiper_inner link">
              <div class="swiper_inner--anim">
                <div class="swiper_inner--anim--bg"></div>
                <lottieAnimation
                  path="animations/expertise/appdev/appdev.json"
                />
              </div>
              <div class="swiper_inner--hl"><h3>IT Beratung & Support</h3></div>
              <div class="swiper_inner--sl">
                <p>
                  Eine Herzensangelegenheit: Gerne helfe und unterstütze ich
                  Anwender in allen möglichen Bereichen (Office, IT)
                </p>
              </div>
            </div></swiper-slide
          >
          <!--           
          <swiper-slide
            ><div class="swiper_inner link">
              <div class="swiper_inner--anim">
                <div class="swiper_inner--anim--bg"></div>
                <lottieAnimation path="animations/expertise/cicd/cicd.json" />
              </div>
              <div class="swiper_inner--hl">
                <h3>Corporate Design & Identity</h3>
              </div>
              <div class="swiper_inner--sl">
                <p>
                  Meine Apps sind komplett responsive und sind auf allen mobilen
                  Endgeräten nutzbar. Vue.js bietet eine solide Basis für
                  anspruchsvolle Apps.
                </p>
              </div>
            </div></swiper-slide
          > -->
          <div class="swiperNavigationContainer">
            <div class="swiper-own-prev"></div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>

            <!-- If we need navigation buttons -->
            <div class="swiper-own-next"></div>
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  Keyboard,
  EffectCoverflow,
} from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";

// modules styles
import "swiper/components/effect-coverflow/effect-coverflow.min.css";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, Keyboard, EffectCoverflow]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    LottieAnimation,
  },
  methods: {
    setAnimController: function (anim) {
      this.anim = anim;
      anim.onComplete = () => console.log("anim erstellt.");
    },
    pause: function () {
      this.anim.pause();
    },
    continuePlay: function () {
      this.anim.play();
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log("Swiper created", swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
<style>
.swiper-slide {
  cursor: pointer;
  border-radius: 5px;
  min-height: 200px;
  color: white;
  background: rgb(119, 55, 230) !important;
  background: -moz-linear-gradient(
    0deg,
    rgba(119, 55, 230, 1) 35%,
    rgba(0, 255, 246, 1) 100%
  ) !important;
  background: -webkit-linear-gradient(
    0deg,
    rgba(119, 55, 230, 1) 35%,
    rgba(0, 255, 246, 1) 100%
  ) !important;
  background: linear-gradient(
    0deg,
    rgba(119, 55, 230, 1) 35%,
    rgba(0, 255, 246, 1) 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7737e6",endColorstr="#00fff6",GradientType=1);
}
.swiper_inner {
  padding: 5px;
}
.swiper_inner--anim {
  position: relative;
  margin: 10px 0 20px;
}

/* Für html5 */
.swiper_inner--anim iframe {
  width: 100%;
}

.swiper_inner--anim--bg {
  background-image: url("~@/assets/svgs/lottiebg.svg");
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.swiper_inner--anim div svg {
  margin: 15px auto;
  max-width: 100%;
}

.swiper_inner--hl {
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
  margin: 10px;
}
.swiper_inner--hl h3 {
  font-size: 1rem;
}

.swiper_inner--sl {
  margin: 30px 15px 5px;
}

.swiper_inner--sl p {
  font-size: 0.7rem;
}

/* Swiper-Navigatioin */
.swiperNavigationContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.swiper-own-prev,
.swiper-own-next {
  width: 30px;
  height: 25px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.swiper-own-prev {
  background-image: url("~@/assets/svgs/arrowleft.svg");
}
.swiper-own-next {
  background-image: url("~@/assets/svgs/arrowright.svg");
}
.swiper-pagination {
  position: relative;
}
.swiper-pagination-bullet {
  background-color: #fff;
  top: 30%;
  margin: 0 2px;
}
</style>
