<template>
  <div
    id="topMenuInactive"
    class="topMenu justify-content-between align-items-center"
    ref="topMenu"
  >
    <router-link to="/" class="logo">
      <div class="logo__first">C. Otters</div>
      <div class="logo__italic">bach</div>
    </router-link>
    <button class="header__button nav-btn-js">MENU</button>
  </div>

  <nav class="header__nav nav-js">
    <div
      id="topMenuActive"
      class="topMenu justify-content-between align-items-center"
    >
      <router-link to="/" class="logo">
        <div class="logo__first">C. Otters</div>
        <div class="logo__italic">bach</div>
      </router-link>
      <button class="header__button nav-btn-js">MENU</button>
    </div>
    <appSocial />
    <ul ref="menuButton">
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/#kontakt">Kontakt</router-link></li>
    </ul>
  </nav>
</template>

<script>
import revealer from "circular-revealer";
import appSocial from "@/components/social.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    appSocial,
  },
  mounted() {
    this.toggleMenu();
    // this.shrinkMenu();
  },
  methods: {
    toggleMenu() {
      const revealerNav = revealer({
        revealElementSelector: ".nav-js",
        options: {
          anchorSelector: ".nav-btn-js",
        },
      });
      const actionBtns = document.querySelectorAll(".nav-btn-js");
      for (const actionBtn of actionBtns) {
        actionBtn.addEventListener("click", () => {
          this.$refs.topMenu.classList.toggle("navOpen");
          if (!revealerNav.isRevealed()) {
            //   document.body.style.overflow = "hidden";
            revealerNav.reveal();
            actionBtn.setAttribute("data-open", true);
          } else {
            revealerNav.hide();
            //   document.body.style.overflow = "auto";
            actionBtn.setAttribute("data-open", false);
          }
        });
      }
      const menuButton = this.$refs.menuButton;
      menuButton.addEventListener("click", () => {
        this.$refs.topMenu.classList.toggle("navOpen");
        if (!revealerNav.isRevealed()) {
          revealerNav.reveal();
          //   actionBtn.setAttribute("data-open", true);
        } else {
          revealerNav.hide();
          //   actionBtn.setAttribute("data-open", false);
        }
      });
    },
    shrinkMenu() {
      setTimeout(() => {
        gsap.to(".topMenu", {
          scrollTrigger: "#intro",
          paddingTop: "5px",
          paddingBottom: "5px",
        });
      }, 4000);
    },
  },
};
</script>

<style>
.link {
  cursor: pointer;
}

.header__nav .social_icons--inner a {
  color: white;
}

.topMenu {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 20px;
  width: 100%;
  z-index: 10;
  color: #000;
  background-color: transparent;
  transition: all 1s;
  font-size: 1rem;
  top: 0;
  left: 0;
}

/* .header__button {
  position: fixed;
  z-index: 999;
  font-size: 1rem;
  padding: 15px 20px;
  right: 0;
  top: 0;
} */
/* .header__button[data-open="true"] {
  color: white;
} */

.header__nav .topMenu {
  background-color: transparent;
  position: fixed;
}

.header__nav .logo,
.header__nav button {
  color: white;
}

.topMenu.navOpen,
.topMenu.navOpen button {
  transition: all 1s;
}

button {
  color: #000000;
  transition: all 1s;
}

.logo {
  display: flex;
  color: #000;
}

.logo__italic {
  padding-left: 1px;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  animation-name: angle;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
  animation-fill-mode: forwards;
}

.header__nav {
  background-color: #201790;
  position: fixed;
  overflow: hidden;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  visibility: hidden;
  clip-path: circle(var(--radius) at calc(100% - 55px) 47px);
}
.header__nav[data-active="true"] {
  visibility: visible;
}

.header__nav ul {
  position: absolute;
  right: 20%;
  top: 50%;
  transform: translateY(-50%);
}
.header__nav ul li {
  list-style-type: none;
  margin: 20px 0;
}
.header__nav ul li a {
  font-family: "Jost", sans-serif;
  font-style: italic;
  display: block;
  color: #fff;
  transform: rotate(-5deg);
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
}

.header__nav ul li a:hover {
  font-style: italic;
  opacity: 1;
}

@keyframes angle {
  0% {
    -webkit-transform: skewX(0deg);
    -moz-transform: skewX(0deg);
    -o-transform: skewX(0deg);
    transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(-20deg);
    -moz-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    transform: skewX(-20deg);
  }
}
</style>
