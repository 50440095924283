<template>
  <div class="social_icons">
    <div class="social_icons--inner">
      <a
        href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#109;&#097;&#105;&#108;&#064;&#099;&#045;&#111;&#116;&#116;&#101;&#114;&#115;&#098;&#097;&#099;&#104;&#046;&#100;&#101;"
      >
        <font-awesome-icon
          icon="mail-bulk"
          rotation="270"
          size="lg"
          swap-opacity
      /></a>
      <a href="https://www.instagram.com/chris_opunkt/">
        <font-awesome-icon
          :icon="['fab', 'instagram']"
          rotation="270"
          size="lg"
        />
      </a>
      <a href="https://www.xing.com/profile/Christian_Ottersbach">
        <font-awesome-icon :icon="['fab', 'xing']" rotation="270" size="lg" />
      </a>
      <a href="www.linkedin.com/in/christian-ottersbach">
        <font-awesome-icon :icon="['fab', 'linkedin']" rotation="270" size="lg"
      /></a>
      <a href="https://www.facebook.com/cottersbach/">
        <font-awesome-icon :icon="['fab', 'facebook']" rotation="270" size="lg"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "appSocial",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.social_icons {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  z-index: 9;
}

.social_icons a {
  color: black;
}

.social_icons a:hover {
  color: #2eb3f0;
}

.social_icons--inner {
  position: relative;
  display: flex;
  flex-direction: column;
  color: black;
}

.social_icons--inner svg {
  margin: 5px 0;
  transition: all 0.5s cubic-bezier(1, -0.67, 0.1, 1.51);
}

.social_icons--inner svg:hover {
  transform: rotate(360deg);
}
</style>
