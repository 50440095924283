<template>
  <section class="fullpage" id="projects">
    <div class="container">
      <div class="row">
        <div class="col mb-5">
          <h2 class="appear rotateFont">Projekte</h2>
          <h6 class="appear rotateFont">
            * Umsetzung Immobilienprojekte in Festanstellung bei Cadman GmbH
          </h6>
        </div>
      </div>
      <div class="row align-items-baseline">
        <div class="col-6">
          <router-link to="/projekte/luxusaufmallorca1" class="appear">
            <video class="videoclass" autoplay muted loop>
              <source
                :src="
                  require('@/assets/img/projekte/luxusaufmallorca1/Home_eingebaut.mp4')
                "
                type="video/mp4"
              />
              <p>Dieser Browser unterstützt html5 nicht.</p>
            </video>
          </router-link>
          <router-link to="/projekte/quartierratingen" class="mt-5 appear"
            ><img
              class="img-fluid"
              src="@/assets/img/projekte/quartierratingen/bild3.png"
              alt=""
          /></router-link>
        </div>
        <div class="col-6 appear">
          <router-link to="/projekte/oak"
            ><img class="img-fluid" src="@/assets/img/ss.jpg" alt=""
          /></router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { defineComponent } from "vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default defineComponent({
  methods: {
    setAnimController: function (anim) {
      this.anim = anim;
      anim.onComplete = () => console.log("anim 1 erstellt.", anim);
    },
    togglePause: function () {
      this.anim.togglePause();
      console.log("Animation gestoppt");
      console.log(this);
    },
    continuePlay: function () {
      this.anim.play();
    },


  },
});
</script>

<style scoped>
.container {
  text-align: center;
}
video {
  width: 100%;
}
</style>
